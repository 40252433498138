
import { Options, mixins } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Notification from "@/app/ui/components/notification/index.vue";
import OverlayPanel from "primevue/overlaypanel";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { STIController } from "@/app/ui/controllers/STIController";
import { DetailStt, DetailSttPiecesSTI } from "@/domain/entities/STI";
import Print from "../modules/generate.vue";
import {
  convertDecimal,
  convertDecimalAfterComma,
  storeDatatoLocalStorage,
  removeDataLocalStorage
} from "@/app/infrastructures/misc/Utils";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import DashboardToastNeedAccess from "@/app/ui/views/dashboard/component/toast-need-action/sti.vue";
import debounce from "lodash/debounce";
import { TrackingController } from "@/app/ui/controllers/TrackingController";

@Options({
  components: {
    DetailLayout,
    OverlayPanel,
    Notification,
    Print,
    DashboardToastNeedAccess
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isPartial) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      STIController.setOpenSuccess(false);
      next();
    }
  }
})
export default class UpdateFormSTI extends mixins(OutGoingMixins) {
  mounted() {
    this.refs = this.$refs;
  }

  // convert value decimal
  isValueDecimal(value: any): number | string {
    return convertDecimal(value);
  }

  // format total kg
  convertTotalKg(value: number) {
    return convertDecimalAfterComma(value, 1);
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }
  get detailDistrict() {
    return LocationController.districtDetail;
  }

  detailSttNotification = {
    sttNumber: "",
    destinationCity: "",
    region: "",
    product: "",
    commodity: "",
    totalPieces: 0,
    codAmount: ""
  };

  pagination = {
    page: 1,
    limit: 50
  };
  sourceSttFromLocalStorage: any = JSON.parse(
    localStorage.getItem("sti") || "[]"
  );
  get dataSttToBeUpdate(): DetailStt[] {
    storeDatatoLocalStorage("sti", this.sourceSttFromLocalStorage);
    return this.sourceSttFromLocalStorage;
  }

  // handle stt number
  isPaid = true;
  isScan = false;
  isIdManifest = false;
  errorSttNumber = "";
  isValidateScan = false;
  isIdManifestAlreadyExist = false;

  onReset() {
    this.isIdManifest = false;
    this.isPartial = false;
    this.isScan = false;
    this.isStiDest = false;
    this.region = "";
    this.isSti = false;
    this.notification = false;
    this.isPaid = true;
    this.form = {
      ...this.form,
      idManifest: "",
      totalStt: 0,
      totalPieces: 0,
      totalSuccess: 0,
      totalFailed: 0
    };
  }
  onValidateSttNumber = debounce(async (val: any) => {
    this.isScan = true;
    const splitVal = val.split("#");
    this.form.sttNumber = splitVal[0];
    await this.onAddSttNumber();
  }, 500);

  get isDisableAddSttNumber() {
    return !this.form.sttNumber || this.totalStt >= 500;
  }
  onChangeInputStt() {
    this.isPaid = true;
  }

  async addSttNumber() {
    this.onReset();
    if (this.form.sttNumber.includes("PUM")) {
      this.isIdManifest = true;
    }

    await this.onAddSttNumber();
  }

  async onAddSttNumber() {
    if (this.isDisableAddSttNumber) {
      playNotification("error");
      return;
    }
    if (this.form.sttNumber) {
      let getTracking = false;
      this.errorSttNumber = "";
      if (this.isLiloBag(this.form.sttNumber)) {
        const res = await STIController.getDetailSttNumberLilo({
          sttNumber: this.form.sttNumber
        });
        this.addSttNumberToGenerate(res, this.form.sttNumber);
      } else {
        if (!this.isSttNumber(this.form.sttNumber)) {
          getTracking = await TrackingController.trackStt({
            sttNo: this.form.sttNumber,
            isGetSla: false
          });
        }
        const sttTrackingData =
          TrackingController.trackingSttData.sttDetail.sttNo;
        const res = await STIController.getDetailSttNumber({
          sttNumber: getTracking ? sttTrackingData : this.form.sttNumber
        });
        this.addSttNumberToGenerate(res, this.form.sttNumber);
      }
    }
  }

  isLiloBag(bagNo: string) {
    return bagNo.trim().startsWith("TKP01-BAG-");
  }

  isSttNumber(sttNumber: string) {
    return /^\d{1,2}LP\w+$/g.test(sttNumber);
  }

  isDestinationEqualOrigin(destination: string) {
    return this.cityOrigin === destination;
  }

  onShowMessageBadge(stiResult: string) {
    const result = stiResult.split("_");
    if (result[0] === "STI") {
      this.sttNumberTypeStatus = {
        icon: "badge-success",
        title: this.region ? `STI - ${this.region}` : "STI",
        color: "bg-green-lp-500"
      };
    }
    if (result[0] === "STI-DEST") {
      this.sttNumberTypeStatus = {
        icon: "badge-success",
        title: this.region ? `STI-DEST - ${this.region}` : "STI-DEST",
        color: "bg-green-lp-500"
      };
    }
    if (result[0] === "STI" && result[1] === "STI-DEST") {
      this.sttNumberTypeStatus = {
        icon: "badge-success",
        title: this.region
          ? `STI & STI-DEST - ${this.region}`
          : "STI & STI-DEST",
        color: "bg-green-lp-500"
      };
    }
  }

  onStiResultScanBySTT(res: any, data: any) {
    if (!res.stiResult) {
      this.isSti = data?.stts[0].isSti;
      this.isStiDest = data?.stts[0].isStiDest;
      this.onSttNumberTypeStatus();
    } else {
      this.onShowMessageBadge(res.stiResult);
    }
  }

  onStiResultScanByPUM(res: any, isAllStiDest: any, isSomeStiDest: any) {
    if (!res.stiResult) {
      this.isSti = !isAllStiDest && !this.isIdManifestAlreadyExist;
      this.isStiDest = isSomeStiDest && !this.isIdManifestAlreadyExist;
      this.onSttNumberTypeStatus();
    } else {
      this.onShowMessageBadge(res.stiResult);
    }
  }

  addSttNumberToGenerate(res: DetailSttPiecesSTI | any, noRefExternal: any) {
    if (res?.totalSuccess) {
      // stt success
      const data = new DetailSttPiecesSTI({
        ...res,
        stts: res.stts.filter((e: DetailStt) => e.isAllowUpdateStatus)
      });

      if (this.form.sttNumber.includes("PUM")) {
        this.onPumValidateData(data, res);
      } else {
        const indexCurrentSttNumber = this.dataSttToBeUpdate.findIndex(
          (exist: DetailStt) => exist.sttNo === data?.stts[0].sttNo
        );

        if (indexCurrentSttNumber > -1) {
          playNotification("error");
          this.errorSttNumber = this.$t("No. STT sudah di input");
          this.isErrorTyping = true;
          return;
        }
        this.isPaid = data?.stts[0].isPaid;
        this.region = data?.stts[0].sttDestinationRegionName;
        this.onStiResultScanBySTT(res, data);
        const isDoubleStt =
          data.stts.length > 1 &&
          !this.isSttDouble(data?.stts, data?.stts[0].sttNo);
        if (this.isLiloBag(this.form.sttNumber) || isDoubleStt) {
          data.stts.forEach((stt: DetailStt) => {
            this.pushDataToBeUpdate(stt, noRefExternal);
          });
        } else {
          this.pushDataToBeUpdate(data?.stts[0], noRefExternal);
        }
      }
      this.onSuccessSttResults(data);
    } else {
      playNotification("error");
      this.isErrorTyping = true;
      this.errorSttNumber =
        (res?.stts.length && res?.stts[0]?.errorMessage) ||
        res?.message?.id ||
        this.$t(
          `${
            this.isIdManifest ? "ID Manifest" : "No. STT"
          } tidak dapat diproses`
        );
    }
  }

  isSttDouble(stts: any, sttNumber: string) {
    const sttAreSame = stts?.filter(
      (item: DetailStt) => item.sttNo === sttNumber
    );
    return sttAreSame?.length === stts?.length;
  }

  onPumValidateData(data: any, res: any) {
    // stt failed
    const sttFailed = res?.stts
      .filter((e: DetailStt) => !e.isAllowUpdateStatus)
      // eslint-disable-next-line @typescript-eslint/camelcase
      .map((e: DetailStt) => ({ stt_no: e.sttNo }));
    STIController.setSttFailed(sttFailed);

    this.form = {
      ...this.form,
      idManifest: this.form.sttNumber,
      totalStt: data.totalStt,
      totalPieces: data.totalPieces,
      totalSuccess: data.totalSuccess,
      totalFailed: data.totalFailed
    };

    data.stts.forEach((stt: DetailStt) => {
      this.pushDataToBeUpdate(stt, this.form.sttNumber);
    });

    // show error if id manifest already input
    this.isPartial =
      !!data.totalSuccess &&
      !!data.totalFailed &&
      !this.isIdManifestAlreadyExist;

    // check is find sti or sti dest or both
    const isAllStiDest = !data.stts.find((e: DetailStt) => !e.isStiDest);
    const isSomeStiDest = !!data.stts.find((e: DetailStt) => e.isStiDest);
    this.onStiResultScanByPUM(res, isAllStiDest, isSomeStiDest);
    this.isPaid = !data.stts.find((e: DetailStt) => !e.isPaid);

    if (this.isIdManifestAlreadyExist) {
      this.errorSttNumber = this.$t("ID Manifest sudah di input");
      this.isErrorTyping = true;
      playNotification("error");
    }
  }

  onSuccessSttResults(data: any) {
    this.detailSttNotification = {
      sttNumber: data?.stts[0].sttNo,
      destinationCity: data?.stts[0].sttDestinationCityId,
      region: data?.stts[0].sttDestinationRegionName,
      product: data?.stts[0].sttProductType,
      commodity: data?.stts[0].sttCommodityName,
      totalPieces: data?.stts[0].sttTotalPiece,
      codAmount: ""
    };
    this.notification =
      !this.isIdManifestAlreadyExist && !this.form.totalFailed;
    if (!this.isIdManifestAlreadyExist) {
      playNotification("success");
      // reset form
      this.onResetForm();
    }
    this.form.sttNumber = "";
    if (!this.isErrorValidationScan) {
      this.isValidateScan = false;
    }

    // remove notification after 5 second
    setTimeout(() => {
      this.notification = false;
      this.isStiDest = false;
      this.region = "";
      this.isSti = false;
    }, 15000);
  }

  pushDataToBeUpdate(data: DetailStt, noRefExternal: any) {
    const currentIdx = this.dataSttToBeUpdate.findIndex(
      (exist: DetailStt) => exist.sttNo === data.sttNo
    );

    if (currentIdx === -1) {
      this.dataSttToBeUpdate.push({
        ...data,
        sttNoRef: this.isSttNumber(this.form.sttNumber)
          ? "-"
          : this.isLiloBag(this.form.sttNumber)
          ? "-"
          : noRefExternal
      });
    } else if (!this.isIdManifestAlreadyExist) {
      this.isIdManifestAlreadyExist = true;
    }
  }

  get totalStt() {
    return this.dataSttToBeUpdate.length;
  }
  get calculatedStt() {
    return this.dataSttToBeUpdate.reduce(
      (a: any, b: any) => {
        return {
          sttTotalPiece: a.sttTotalPiece + b.sttTotalPiece,
          sttGrossWeight:
            a.sttGrossWeight +
            b.piece.reduce((c: any, d: any) => {
              return c + d.sttPieceGrossWeight;
            }, 0),
          sttVolumeWeight:
            a.sttVolumeWeight +
            b.piece.reduce((c: any, d: any) => {
              return c + d.sttPieceVolumeWeight;
            }, 0)
        };
      },
      {
        sttTotalPiece: 0,
        sttGrossWeight: 0,
        sttVolumeWeight: 0
      }
    );
  }
  get totalPieces() {
    return this.calculatedStt.sttTotalPiece;
  }
  get totalGrossWeight() {
    return this.calculatedStt.sttGrossWeight;
  }
  get totalVolumeWeight() {
    return this.calculatedStt.sttVolumeWeight;
  }

  // notification handler
  // validate stt number
  notification = false;
  isStiDest = false;
  region = "";
  isSti = false;
  sttNumberTypeStatus: any = false;
  onSttNumberTypeStatus() {
    if (this.isSti && this.isStiDest)
      this.sttNumberTypeStatus = {
        icon: "badge-success",
        title: this.region
          ? `STI & STI-DEST - ${this.region}`
          : "STI & STI-DEST",
        color: "bg-green-lp-500"
      };
    if (this.isSti)
      this.sttNumberTypeStatus = {
        icon: "badge-success",
        title: this.region ? `STI - ${this.region}` : "STI",
        color: "bg-green-lp-500"
      };
    if (this.isStiDest)
      this.sttNumberTypeStatus = {
        icon: "badge-success",
        title: this.region ? `STI-DEST - ${this.region}` : "STI-DEST",
        color: "bg-green-lp-500"
      };
    return false;
  }

  // delete stt number
  sttNumberListTrash: Array<string> = [];
  showDeleteSttNumber = false;
  sttNumberToDelete = "";
  sttNumberSuccessDelete = "";
  errorSttNumberDelete = "";
  async onValidateDeleteSttNumber(val: any) {
    const splitVal = val.split("#");
    if (splitVal.length > 0) this.sttNumberToDelete = splitVal[0];
    this.onDeleteSttNumber();
  }
  onDeleteSttNumber() {
    if (this.sttNumberToDelete) {
      this.errorSttNumberDelete = "";
      this.sttNumberSuccessDelete = "";
      this.sttNumberToDelete = this.sttNumberToDelete.toUpperCase();

      // find index no. stt
      const indexSttNumber = this.dataSttToBeUpdate.findIndex(
        (data: DetailStt) => {
          return data.sttNo === this.sttNumberToDelete;
        }
      );

      // no. stt not found
      if (indexSttNumber < 0) {
        this.errorSttNumberDelete = this.$t(
          "No. STT tidak ditemukan/sudah terhapus"
        );
      } else {
        this.dataSttToBeUpdate.splice(indexSttNumber, 1);
        this.sttNumberListTrash.push(this.sttNumberToDelete);
        this.sttNumberSuccessDelete = this.sttNumberToDelete;
        this.sttNumberToDelete = "";
      }
    }
    const deleteStt: any = this.$refs.deleteStt;
    deleteStt?.focus();
    if (!this.dataSttToBeUpdate.length) this.sttNumberTypeStatus = false;
  }
  onShowDelete() {
    this.errorSttNumberDelete = "";
    this.showDeleteSttNumber = true;
    this.sttNumberToDelete = "";
    this.sttNumberSuccessDelete = "";
  }
  onCloseDelete() {
    this.showDeleteSttNumber = false;
    this.sttNumberListTrash = [];
  }

  // alert popup
  isErrorTyping = false;
  isErrorValidationScan = false;
  isPartial = false;
  isFailed = false;
  get isResponseUpdate() {
    return (
      this.openSuccess ||
      this.isErrorTyping ||
      this.isFailed ||
      this.isPartial ||
      this.isErrorValidationScan
    );
  }
  refs = "" as any;
  onResetForm() {
    this.form.sttNumber = "";
    this.errorSttNumber = "";
    this.isErrorTyping = false;
    this.isErrorValidationScan = false;
    // re focus
    const inputStt: any = this.refs.inputStt;
    inputStt.focus();
  }
  get sttFailedUpdate() {
    return STIController.sttFailed;
  }
  get alertPopup() {
    // if success
    if (this.openSuccess)
      return {
        onClick: () => this.onCloseSuccess(),
        title: this.$t("Perubahan Status STT Berhasil !"),
        message: this.$t("Status STT berhasil diupdate menjadi STI."),
        image: "image-modal-success"
      };
    // if error typing
    if (this.isErrorTyping)
      return {
        onClick: () => this.onResetForm(),
        title: this.$t(
          `${this.isScan ? "Scan" : "Tambah"} ${
            this.isIdManifest ? "ID Manifest" : "No. STT"
          } Gagal!`
        ),
        message: this.errorSttNumber,
        image: "image-modal-failed"
      };
    // if error scan
    if (this.isErrorValidationScan)
      return {
        onClick: () => this.onResetForm(),
        title: this.$t("Scan No. STT Gagal"),
        message: this.errorSttNumber,
        image: "image-modal-failed"
      };
    // if success partial
    if (this.isPartial) return this.onPartialData;
    // default is failed
    return {
      onClick: () => (this.isFailed = false),
      onDownload: () =>
        this.downloadCsvFailedStt({
          fileName: "sti_stt_failed.csv",
          listStt: this.sttFailedUpdate
        }),
      title: this.$t("Perubahan Status Gagal !"),
      message: this.$t("Perubahan status STI gagal dibuat."),
      image: "image-modal-failed"
    };
  }

  get onPartialData() {
    return {
      onClick: this.onCloseSuccess,
      onDownload: () =>
        this.downloadCsvFailedStt({
          fileName:
            this.form.totalSuccess && this.form.totalFailed
              ? "sti_stt_scan_manifest_failed.csv"
              : "sti_stt_failed.csv",
          listStt: this.sttFailedUpdate
        }),
      title:
        this.form.totalSuccess && this.form.totalFailed
          ? this.$t("Tambah No. Id Manifest Berhasil!")
          : this.$t("Perubahan Status Berhasil !"),
      message:
        this.form.totalSuccess && this.form.totalFailed
          ? this.$t(
              "Beberapa STT berhasil ditambahkan, namun terdapat kegagalan pada beberapa STT. STT yang gagal tidak terdapat di dalam list."
            )
          : this.$t(
              "Beberapa status STT berhasil diupdate menjadi <b>“STI”</b>, namun terdapat kegagalan pada beberapa STT. Silahkan download untuk mengetahui kesalahan."
            ),
      image: "image-modal-warning"
    };
  }

  // form model
  form = {
    pickupDate: new Date(),
    sttNumber: "",
    idManifest: "",
    totalStt: 0,
    totalPieces: 0,
    totalSuccess: 0,
    totalFailed: 0
  };
  get partnerName() {
    return `${this.userType} - ${this.dataProfile.account_type_detail.company_name}`;
  }
  get userType() {
    return `${this.dataProfile.account_type
      .charAt(0)
      .toUpperCase()}${this.dataProfile.account_type.slice(
      1,
      this.dataProfile.account_type.length
    )}`;
  }

  get cityOrigin() {
    const detail = LocationController.districtDetail;
    return detail.cityCode;
  }

  // panel pickup
  togglePanel(event: any) {
    this.onReset();
    const refs: any = this.$refs;
    refs.op?.toggle(event);
  }

  // table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-24 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (_: any, index: number) => {
          return `<div class="text-black-lp-300">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. STT",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: DetailStt) => {
          return `<div class="space-y-3"><div class="text-black-lp-300">${item.sttNo}</div></div>`;
        }
      },
      {
        name: this.$t("No. Referensi"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: DetailStt) => {
          return `<div class="space-y-3"><div class="text-black-lp-300">${item.sttNoRef}</div></div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-32 text-left whitespace-no-wrap",
        styleCustom: "justify-center items-center",
        render: (item: DetailStt) => {
          return `<div class="space-y-3 text-center"><div class="text-black-lp-300">${item.sttTotalPiece}</div></div>`;
        }
      },
      {
        name: this.$t("Berat Kotor"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: DetailStt) => {
          return `<div class="space-y-3"><div class="text-black-lp-300">${this.isValueDecimal(
            this.convertTotalKg(item.sttGrossWeight)
          )} Kg</div></div>`;
        }
      },
      {
        name: this.$t("Berat Dimensi"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: DetailStt) => {
          return `<div class="space-y-3"><div class="text-black-lp-300">${this.isValueDecimal(
            this.convertTotalKg(item.sttVolumeWeight)
          )} Kg</div></div>`;
        }
      },
      {
        name: this.$t("Dest."),
        styleHead: "w-32 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: DetailStt) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttDestinationCityId}
                  </div>
                </div>`;
        }
      },
      {
        name: "STI",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: DetailStt) => {
          return `<div class="space-y-3"><div class="text-black-lp-300">${
            item.isSti ? this.$t("Ya") : this.$t("Tidak")
          }</div></div>`;
        }
      },
      {
        name: "STI-DEST",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: DetailStt) => {
          return `<div class="space-y-3"><div class="text-black-lp-300">${
            item.isStiDest ? this.$t("Ya") : this.$t("Tidak")
          }</div></div>`;
        }
      },
      {
        name: this.$t("Wilayah"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: DetailStt) => {
          return `<div class="space-y-3"><div class="text-black-lp-300">${item.sttDestinationRegionName}</div></div>`;
        }
      }
    ];
  }

  // on pickup
  withPrint = false;
  countSuccess = 0;
  countFailed = 0;
  async onSave() {
    const refs: any = this.$refs;
    this.handleConfirmationSave(false);
    const resp = await STIController.onGenerateSti({
      sttNumbers: this.dataSttToBeUpdate.map((item: DetailStt) => item.sttNo)
    });
    if (resp.data) {
      if (resp.data.total_stt_failed > 0 && resp.data.total_stt_success === 0) {
        this.countFailed = resp.data.total_stt_failed;
        this.isFailed = true;
        STIController.setSttFailed(resp.data.stt_failed);
      } else if (
        resp.data.total_stt_failed > 0 &&
        resp.data.total_stt_success > 0
      ) {
        this.countFailed = resp.data.total_stt_failed;
        this.countSuccess = resp.data.total_stt_success;
        this.isPartial = true;
        STIController.setSttFailed(resp.data.stt_failed);
      } else {
        STIController.setOpenSuccess(true);
        if (this.withPrint) {
          refs.print.print(STIController.stiIdToGeneratePdf);
        }
      }
      this.sourceSttFromLocalStorage = [];
    }
    this.handleConfirmationSave(false);
  }
  pickupAndPrint(withPrint: boolean) {
    const refs: any = this.$refs;
    refs.op?.hide();
    this.withPrint = withPrint;
    this.handleConfirmationSave(true);
  }

  // handle route leave and modal
  // navigation
  get openSuccess() {
    return STIController.isOpenSucess;
  }
  openConfirmationLeave = false;
  openConfirmationSave = false;
  answer = false;
  newPath = "";
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  handleConfirmationSave(value: boolean) {
    this.openConfirmationSave = value;
  }
  goBack() {
    this.$router.push("/station-transit-in");
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    removeDataLocalStorage("sti");
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }
  onCloseSuccess() {
    if (!this.isIdManifest) {
      this.goBack();
    }
    this.isPartial = false;
  }

  get dashboardDetailData() {
    return {
      endKey: "targetSti",
      lastStatus: "on_process_bkd_to_sti_cycle_"
    };
  }
}
