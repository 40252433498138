import { getHubData } from "@/app/infrastructures/misc/Cookies";
import { StiRequestInterface } from "../contracts/StiApiRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";
/* eslint-disable @typescript-eslint/camelcase */
export class GenerateStiRequest implements StiRequestInterface {
  private sttNumbers: Array<string>;

  constructor(stiPiece: Array<string>) {
    this.sttNumbers = stiPiece;
  }

  public toJSON(): string {
    return JSON.stringify({
      stt_no: this.sttNumbers,
      hub_id: getHubData()?.hubId,
      hub_name: getHubData()?.hubName,
      hub_origin_city: getHubData()?.originCity,
      hub_district_code: getHubData()?.hubDistrictCode
    });
  }
}

export class StiListRequest {
  page = 1;
  limit = 10;
  startDate = "";
  endDate = "";
  search = "";
  constructor(fields?: Partial<StiListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
