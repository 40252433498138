
import { Options, Vue } from "vue-class-component";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import { STIController } from "@/app/ui/controllers/STIController";
import { OUTGOING_SHIPMENT_STI } from "@/app/infrastructures/misc/RolePermission";
import Print from "./component/modules/generate.vue";
import { STIData } from "@/domain/entities/STI";

@Options({
  components: {
    Print
  }
})
export default class STI extends Vue {
  print: any = "";
  beforeMount() {
    STIController.setFilter({
      startDate: "",
      endDate: "",
      search: ""
    });
  }
  mounted() {
    this.print = this.$refs.print;
    STIController.initPagination();
  }

  get getTitle() {
    return this.$route.meta.name;
  }
  get ableToCreate() {
    return checkRolePermission(OUTGOING_SHIPMENT_STI.CREATE);
  }
  get ableToDetail() {
    return checkRolePermission(OUTGOING_SHIPMENT_STI.DETAIL);
  }

  // filter search
  get searchValue() {
    return STIController.search;
  }

  onSearch(value: string) {
    STIController.setSearch(value);
    STIController.setFirstPage();
    this.fetchSTIList();
  }

  clearSearch() {
    STIController.setSearch("");
    this.fetchSTIList();
  }

  // filter date
  get periodeStart() {
    return STIController.periodeStart;
  }

  get periodeEnd() {
    return STIController.periodeEnd;
  }

  setPeriode(value: Array<any>) {
    STIController.setPeriodeStart(value[0]);
    STIController.setPeriodeEnd(value[1]);
    value[0] && value[1] && this.fetchSTIList();
  }

  fetchSTIList() {
    STIController.fetchSTIList();
  }

  // goToUpdate
  goToUpdate() {
    this.$router.push("/station-transit-in/update");
  }

  get isLoading() {
    return STIController.isLoading;
  }

  get errorCause() {
    return STIController.errorCause;
  }
  // Table
  get firstColumns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left",
        render: (item: STIData, index: any) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "Manifest ID",
        styleHead: "w-24 whitespace-nowrap text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiId}</div>`;
        }
      },
      {
        name: this.$t("Nama Consolidator"),
        styleHead: "w-52 text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiPartnerName}</div>`;
        }
      }
    ];
  }
  get secondColumns() {
    return [
      {
        name: this.$t("Total STT"),
        styleHead: "w-32 text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiTotalStt}</div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-36 text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiTotalPieces}</div>`;
        }
      },
      {
        name: this.$t("Total Gross Weight"),
        styleHead: "w-56 text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.stiTotalGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Total Volume Weight"),
        styleHead: "w-56 text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.stiTotalVolumeWeight,
            2
          )} Kg</div>`;
        }
      }
    ];
  }
  get columns() {
    return [
      ...this.firstColumns,
      ...this.secondColumns,
      {
        name: this.$t("Tanggal Dibuat"),
        styleHead: "w-64 text-left",
        render: (item: STIData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.stiCreatedAt
          )}<div class="overflow-ellipsis text-gray-lp-600">${
            item.stiCreatedName
          }</div></div>`;
        }
      },
      {
        name: "Action",
        key: "button_column",
        styleHead: "w-24 text-left",
        styleCustom: "padding-action-sti",
        styleButton: (item: STIData) => {
          return {
            outline: true,
            icon: "option-gray",
            title: "",
            textColor: "black",
            color: "gray-lp-400",
            clickFunction: () => null,
            groupOptions: [
              {
                icon: "print-summary",
                label: this.$t("Print Summary"),
                clickFunction: () => this.print.printSummary(item.stiId)
              },
              {
                icon: "printer",
                label: this.$t("Print PDF"),
                clickFunction: () => this.print.print(item.stiId)
              },
              {
                icon: "document-download-outline-grey",
                label: this.$t("Download Excel"),
                clickFunction: () => STIController.downloadSti(item.stiId)
              }
            ]
          };
        }
      }
    ];
  }

  get pagination() {
    return STIController.STIList.pagination;
  }

  get STIList() {
    return STIController.STIList.data;
  }

  goToDetail(item: STIData) {
    this.$router.push(
      `/station-transit-in/detail/${item.stiId}?params=STI Manifest - ${item.stiId}`
    );
  }
}
