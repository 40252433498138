import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  DetailSttPiecesSTI,
  STIEntities,
  StiManifestData
} from "@/domain/entities/STI";
import { STIPresenter } from "@/app/ui/presenters/STIPresenter";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  GenerateStiRequest,
  StiListRequest
} from "@/data/payload/api/StiApiRequest";
import { MainAppController } from "./MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";

export interface STIState {
  isLoading: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "sti" })
class STIStore extends VuexModule implements STIState {
  isLoading = false;
  isError = false;
  errorCause = "";
  public periodeStart: any = null;
  public periodeEnd: any = null;
  public STIList = new STIEntities(new Pagination(0, 0), []);
  public isOpenSucess = false;
  public isLoadingPrint = false;
  public isErrorPrint = false;
  public errorPrintCause = "";
  public stiIdToGeneratePdf = 0;
  public stiManifestData = new StiManifestData();
  public sttFailed: Array<string> = [];
  public search = "";

  @Action
  public getDetailSttNumber(params: { sttNumber: string }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(STIPresenter);
    return presenter
      .getDetailSttPieceNumberSti(params.sttNumber)
      .then((res: DetailSttPiecesSTI) => {
        return res;
      })
      .catch((err: any) => {
        playNotification("error");
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            `Tambah ${
              params.sttNumber.includes("PUM") ? "ID Manifest" : "No. STT"
            } Gagal!`,
            () => this.getDetailSttNumber(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public getDetailSttNumberLilo(params: { sttNumber: string}) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(STIPresenter);
    return presenter
      .getDetailSttPieceNumberStiLilo(params.sttNumber)
      .then((res: DetailSttPiecesSTI) => {
        return res;
      })
      .catch((err: any) => {
        playNotification("error");
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            `Tambah No. STT Gagal!`,
            () => this.getDetailSttNumberLilo(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public getListSTI(params: StiListRequest) {
    this.setIsLoading(true);
    const presenter = container.resolve(STIPresenter);
    return presenter
      .getListSTI(params)
      .then((res: STIEntities) => {
        this.setSTIList(res);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
        this.setSTIList(new STIEntities(new Pagination(0, 0), []));
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  public fetchSTIList() {
    this.getListSTI(
      new StiListRequest({
        page: this.STIList.pagination.page,
        limit: this.STIList.pagination.limit,
        startDate: this.periodeStart.toISOString().slice(0, 10),
        endDate: this.periodeEnd.toISOString().slice(0, 10),
        search: this.search
      })
    );
  }

  @Mutation
  initPagination() {
    this.STIList.pagination.page = 1;
  }

  @Action
  public validateSttPiece(sttPiece: string) {
    const presenter = container.resolve(STIPresenter);
    return presenter
      .validationScanSTTPiece(sttPiece)
      .then(() => {
        return {
          allowToPickup: true,
          errorMessage: ""
        };
      })
      .catch((err: any) => {
        return {
          allowToPickup: false,
          errorMessage:
            (err.response && err.response.data.message.id) ||
            "No. STT Pieces tidak ditemukan"
        };
      });
  }

  @Action
  public onGenerateSti(params: { sttNumbers: Array<string> }) {
    this.setIsLoading(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(STIPresenter);
    return presenter
      .generateSti(new GenerateStiRequest(params.sttNumbers))
      .then((res: ResponsePayload) => {
        this.setStiIdToGeneratePdf(res.data.sti_id);
        this.setIsError(false);
        this.setErrorCause("");
        return res;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Status Gagal!", () =>
            this.onGenerateSti(params)
          )
        );
        this.setOpenSuccess(false);
        return new ResponsePayload();
      })
      .finally(() => {
        this.setIsLoading(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public getStiManifestDetail(params: { id: number }) {
    this.setIsLoadingPrint(true);
    const presenter = container.resolve(STIPresenter);
    return presenter
      .getStiManifestData(params.id)
      .then((res: StiManifestData) => {
        this.setStiManifestData(res);
        this.setErrorPrint(false);
        this.setErrorPrintCause("");
        return true;
      })
      .catch((err: any) => {
        this.setErrorPrint(true);
        this.setErrorPrintCause(err.response ? "server" : "internet");
        this.setStiManifestData(new StiManifestData());
        return false;
      })
      .finally(() => this.setIsLoadingPrint(false));
  }

  @Action
  public downloadSti(id: number) {
    MainAppController.showLoading();
    const presenter = container.resolve(STIPresenter);
    presenter
      .downloadSti(id)
      .catch(err =>
        MainAppController.showErrorMessage(parsingErrorResponse(err))
      )
      .finally(() => MainAppController.closeLoading());
  }

  @Mutation
  setFirstPage() {
    this.STIList.pagination.page = 1;
  }

  @Mutation
  setIsLoadingPrint(val: boolean) {
    this.isLoadingPrint = val;
  }

  @Mutation
  setStiIdToGeneratePdf(val: number) {
    this.stiIdToGeneratePdf = val;
  }

  @Mutation
  setStiManifestData(val: StiManifestData) {
    this.stiManifestData = val;
  }

  @Mutation
  setErrorPrint(val: boolean) {
    this.isErrorPrint = val;
  }

  @Mutation
  setErrorPrintCause(val: string) {
    this.errorPrintCause = val;
  }

  @Mutation
  public setSTIList(value: STIEntities) {
    this.STIList = value;
  }

  @Mutation
  public setOpenSuccess(val: boolean) {
    this.isOpenSucess = val;
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setIsError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setPeriodeStart(value: Date) {
    this.periodeStart = value;
  }

  @Mutation
  public setPeriodeEnd(value: Date) {
    this.periodeEnd = value;
  }

  @Mutation
  public setSttFailed(value: Array<string>) {
    this.sttFailed = value;
  }

  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  public setFilter(val: any) {
    this.periodeStart = val.periodeStart;
    this.periodeEnd = val.periodeEnd;
    this.search = val.search;
  }
}

export const STIController = getModule(STIStore);
