
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import OverlayPanel from "primevue/overlaypanel";
import Book from "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue";
import {
  convertDecimalWithComma,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { STIController } from "@/app/ui/controllers/STIController";
import Print from "../modules/generate.vue";
import { StiManifestStt } from "@/domain/entities/STI";

@Options({
  components: {
    DetailLayout,
    CreateUpdate,
    Title,
    OverlayPanel,
    Book,
    Print
  }
})
export default class DetailBooking extends Vue {
  refs: any = "";
  mounted() {
    this.refs = this.$refs;
    this.getDetail();
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  get title() {
    return `STI Manifest - ${this.id}`;
  }

  // route navigation
  goBack() {
    this.$router.push("/station-transit-in");
  }

  getDetail() {
    STIController.getStiManifestDetail({ id: this.id });
  }

  get detailData() {
    return STIController.stiManifestData;
  }

  get convertCreatedAt() {
    return formatDate(this.detailData.stiDate);
  }

  get isLoading() {
    return STIController.isLoadingPrint;
  }
  get isError() {
    return STIController.isErrorPrint;
  }
  get errorCause() {
    return STIController.errorPrintCause;
  }

  //table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-10 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: StiManifestStt, index: number) => {
          return `<div class="text-black-lp-300">${index + 1}</div>`;
        }
      },
      {
        name: `No. STT`,
        styleHead: "w-48 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: StiManifestStt) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">${item.sttNo}</div>`;
        }
      },
      {
        name: this.$t("Produk"),
        styleHead: "w-32 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: StiManifestStt) => {
          return `<div class="text-black-lp-300 rounded px-2 bg-gray-lp-400">
                      ${item.sttProductType}
                  </div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-32 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: StiManifestStt) => {
          return `<div class="text-black-lp-300">${item.sttTotalPiece}</div>`;
        }
      },
      {
        name: this.$t("Berat Kotor"),
        styleHead: "w-48 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: StiManifestStt) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.stiGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Berat Dimensi"),
        styleHead: "w-48 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: StiManifestStt) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.stiVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Origin",
        styleHead: "w-24 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: StiManifestStt) => {
          return `<div class="text-black-lp-300 rounded px-2 py-0 bg-gray-lp-400 whitespace-nowrap">
                      ${item.stiOriginCity}
                  </div>`;
        }
      },
      {
        name: this.$t("Dest."),
        styleHead: "w-24 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: StiManifestStt) => {
          return `<div class="text-black-lp-300 rounded px-2 py-0 bg-gray-lp-400 whitespace-nowrap">
                      ${item.stiDestCity}
                  </div>`;
        }
      },
      {
        name: this.$t("Wilayah"),
        styleHead: "w-48 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: StiManifestStt) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">
                      ${item.stiDestRegion}
                  </div>`;
        }
      }
    ];
  }

  // handle print

  printSummary() {
    const data = this.detailData;
    this.refs.print?.printSummary(data.stiId, true);
  }

  printManifest() {
    const data = this.detailData;
    this.refs.print?.print(data.stiId, true);
  }
}
